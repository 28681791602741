import React from 'react';
import Layout from 'components/layout';
import {Link} from "gatsby";
import SeoComponent from 'components/seo';
import { Card, Hero, Breadcrumb } from "components/ui";
import HeroImage from "assets/images/hero-default.jpg";
import Bibliography from "assets/images/research-bibliography.jpg";
import Statistics from "assets/images/research-statistics.jpg";
import KeyLegislation from "assets/images/research-key-legislation.jpg";



// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const trainingCardData = [{title:"Child Welfare and Substance Use Disorder Treatment Statistics", link: "child-welfare-statistics"}, {title:"Interactive Bibliography Search", link: "bibliography"}, {title:"Key Legislation", link: "key-legislation"}];

const ResearchLandingPage = ({ location }) => {

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    return (
        <Layout location={location}>
            <SeoComponent title="Research" description="Learn more about the latest child welfare services and substance use treatment statistics and research literature related to families affected by substance use disorders and involvement with child welfare services." keywords=""/>
            <div className="margin-bottom-5">
                <Hero
                img={HeroImage}
                color="#f5f5f5"
                >
                    <Breadcrumb pathname={thisLocationPathname} />
                    <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label="Hero: Research">Research</h1>
                    <p>Learn more about the latest child welfare services and substance use treatment statistics and research literature related to families affected by substance use disorders and involvement with child welfare services.</p>
                </Hero>
            </div>
            <div className="grid-container padding-bottom-5">
                <div className="usa-card-group">
                    {trainingCardData.map((training,idx)=>{
                        return (
                            <Link to={training.link} className="desktop:grid-col-4 tablet:grid-col-6 margin-bottom-2 text-no-underline" key={`training-${idx+1}`}>
                                <Card grid="width-full height-full">
                                    <Card.Header><h2 className="usa-card__heading green">{training.title}</h2></Card.Header>
                                    <Card.Media>
                                        <div className="usa-card__img">
                                            {(() => {
                                            switch(training.title) {
                                                case "Child Welfare and Substance Use Disorder Treatment Statistics":
                                                    return <img src={Statistics} alt="" />
                                                case "Interactive Bibliography Search":
                                                    return <img src={Bibliography} alt="" />
                                                case "Key Legislation":
                                                    return <img src={KeyLegislation} alt="" />
                                                default:
                                                    return <img src={HeroImage} alt=""/>
                                            }})()}
                                        </div>
                                    </Card.Media>
                                </Card>
                            </Link>
                        )})
                    }
                </div>
            </div>
        </Layout>
    )};

export default ResearchLandingPage;
